<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                :aria-label="$t(`scanModal.close`)"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <Vue3Lottie
                ref="icon"
                :animationData="iconJSON"
                :height="160"
                :width="160"
                :loop="loop"
                :key="iconKey"
              />
            </div>
            <div class="col-12 text-center">
              <h1 class="bold-19">{{ title }}</h1>
              <p class="regular-14 mb-4" v-html="text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");
import { Vue3Lottie } from "vue3-lottie";
import scanJSON from "../../assets/animations/scan.json";
import successJSON from "../../assets/animations/success.json";
import errorJSON from "../../assets/animations/error.json";
import searchingJSON from "../../assets/animations/searching.json";
import packagingJSON from "../../assets/animations/packaging.json";
import emptyBoxJSON from "../../assets/animations/emptyBox.json";
import deliveryJSON from "../../assets/animations/delivery.json";


export default {
  name: "ScanModal",
  props: {
    title: String,
    text: String,
    icon: Number,
  },
  data() {
    return {
      myModal: undefined,
      iconJSON: scanJSON,
      loop: true,
      iconKey: 0,
    };
  },
  watch: {
    icon() {
      if (this.icon === 1) {
        this.iconJSON = successJSON;
        this.loop = false;
      } else if (this.icon === 2) {
        this.iconJSON = errorJSON;
        this.loop = false;
      } else if (this.icon === 3) {
        this.iconJSON = searchingJSON;
        this.loop = true;
      } else if (this.icon === 4) {
        this.iconJSON = packagingJSON;
        setTimeout(() => {
          this.$refs.icon.pause();
        }, 3600);
        this.loop = false;
      } else if (this.icon === 5) {
        this.iconJSON = emptyBoxJSON;
        this.loop = false;
      } else if (this.icon === 6) {
        this.iconJSON = deliveryJSON;
        this.loop = true;
      } else {
        this.iconJSON = scanJSON;
        this.loop = true;
      }
      this.iconKey++;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
  },
  components: {
    Vue3Lottie,
  },
};
</script>
